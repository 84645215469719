import React from "react";
import Layout from "../components/layout/layout";
import "./frontpage.scss"

export default () => {

  return (
    <Layout>
      <main>
        <section>

          <p>Hoi ik ben <a href="https://albert.skibinski.nl">Albert Skibinski</a> en op deze site verzamel ik recepten die wij thuis heel lekker vinden.</p>
          <p>Contact via albert.skibinski@gmail.com.</p>

        </section>
      </main>
    </Layout>
  )
};
